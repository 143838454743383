import type { ForwardedRef } from 'react';
import { createElement, forwardRef } from 'react';
import cn from 'clsx';

import styles from './BaseButton.module.scss';

export type BaseButtonProps<T extends keyof JSX.IntrinsicElements = 'button'> = {
  component?: T;
} & JSX.IntrinsicElements[T];

const Button = <T extends keyof JSX.IntrinsicElements = 'button'>(
  { component = 'button' as T, children, className, ...props }: BaseButtonProps<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: ForwardedRef<any>,
) => {
  return createElement(
    component,
    {
      className: cn(styles.button, className),
      ref,
      ...props,
    },
    children,
  );
};

export const BaseButton = forwardRef(Button) as unknown as <T extends keyof JSX.IntrinsicElements = 'button'>(
  props: BaseButtonProps<T>,
) => ReturnType<typeof Button>;
